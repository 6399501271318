import React from "react"
import PropTypes from "prop-types"
import styles from "./view.module.css"
import { Container, Row, Col } from 'react-bootstrap';

const View = ({ title, children }) => (
    <Container>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
)

View.propTypes = {
  title: PropTypes.string.isRequired,
}

export default View

import React from "react"
import { Link } from "gatsby"
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'

const Header = () => (
  <Navbar bg="light" expand="lg">
  <Navbar.Brand href="#home">Donorflow Admin</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
        <Link to="/app/profile">
        <a class="nav-link">
          Profile
        </a>        
      </Link>
      <Link to="/app/dashboard">
        <a class="nav-link">
          Customers
        </a>        
      </Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
)

export default Header
